// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beliefs-js": () => import("./../../../src/pages/beliefs.js" /* webpackChunkName: "component---src-pages-beliefs-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dan-leslie-memorial-js": () => import("./../../../src/pages/dan-leslie-memorial.js" /* webpackChunkName: "component---src-pages-dan-leslie-memorial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infocovid-19-js": () => import("./../../../src/pages/infocovid19.js" /* webpackChunkName: "component---src-pages-infocovid-19-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-mdc-js": () => import("./../../../src/pages/mdc.js" /* webpackChunkName: "component---src-pages-mdc-js" */),
  "component---src-pages-members-hwmr-verses-js": () => import("./../../../src/pages/members/hwmr-verses.js" /* webpackChunkName: "component---src-pages-members-hwmr-verses-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-testimonies-js": () => import("./../../../src/pages/testimonies.js" /* webpackChunkName: "component---src-pages-testimonies-js" */)
}

